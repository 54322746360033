import { TileTypeCode } from "./TileTypeCode";

export default class TileType {
    constructor(name: string, tileType: TileTypeCode, description: string) {
        this.name = name;
        this.tileType = tileType;
        this.description = description;
    }
    public name: string = "";
    public tileType: TileTypeCode = TileTypeCode.None;
    public description: string = "";
}