import React from 'react';
import '../css/app.css';

export default function HamburgerMenuItem(props: any) {
    const { caption, clicked } = props;
   return (
       <div className="hamburger-menu-item">
           <div onClick={() => clicked()}>{caption}</div>
        </div>
    );
}