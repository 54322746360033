import React from 'react';
import '../../css/app.css';
import './CookieConsent.css';
import { GetPrivacyConsentCookie, PrivacyCookieProps, SetPrivacyConsentCookie } from './PrivacyCookies';
import persistentCookie from './persistent-cookie.png';
import PrivacySettings from './PrivacySettings';
import CloseButton from '../../components/CloseButton';

export default function CookieConsent() {
    const handleAcceptAllClick = () => {
        SetPrivacyConsentCookie({ cookiePreferencesSet: true, functionalCookies: true, performanceCookies: true, targetingCookies: true });
    }
    const handleRejectAllClick = (e: { preventDefault: () => void; }) => {
        SetPrivacyConsentCookie({ cookiePreferencesSet: true, functionalCookies: false, performanceCookies: false, targetingCookies: false });
    }
    const onCloseButton = (e: any) => {
        SetPrivacyConsentCookie({ cookiePreferencesSet: true, functionalCookies: true, performanceCookies: true, targetingCookies: true });
        e.preventDefault();
    }
    return (
        <div className="cookie-consent">
            <div className="cookie-consent-body">
                <div>
                    <img src={persistentCookie} className="persistent-cookie" alt="persistent cookie" />
                </div>
                <div className="cookie-consent-text">
                    <p>
                        By clicking <i>Accept All Cookies</i>, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
                    </p>
                    <p>
                        If you want more control over which cookies are used, use <i>Privacy Settings</i> to set your preferences.
                    </p>
                    <div className="cookie-consent-buttons">
                        <PrivacySettings />
                        <button type="button" onClick={handleRejectAllClick}>Reject All Cookies</button>
                        <button type="button" onClick={handleAcceptAllClick}>Accept All Cookies</button>
                    </div>
                </div>
            </div>
            <div><CloseButton onClick={onCloseButton} /></div>
        </div>
    );
}
export function HasCookieConsent(): boolean {
    var privacyConsentCookie: PrivacyCookieProps | null = null;

    privacyConsentCookie = GetPrivacyConsentCookie();

    return privacyConsentCookie !== null;
}
