import React from 'react';
import '../css/app.css';
import { MailToPrivacyLink } from '../AppMetadata';
import './PrivacyPage.css';

export default function PrivacyContactSection() {
    return (
        <div>
            <h2>Contact Us</h2>
            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <MailToPrivacyLink />.</p>
        </div>
    );
}
