import React, { useState } from 'react';
import '../css/app.css';
import Hamburger from '../components/Hamburger';
import BoardFootCalculatorPad from './BoardFootCalculatorPad';
import './Calculator.css';
import CalculatorMenu from './CalculatorMenu';

export default function BasicCalculator() {
    const [menuOpen, setMenuOpen] = useState(false);
    const calculatorTitle = 'Board Foot Calculator';
    /* eslint-disable */
    var evaluate = eval;
    /* eslint-enable */
    return (
        <div className="calculator">
            <CalculatorMenu open={menuOpen} setOpen={setMenuOpen} />
            <div className="calculator-titlebar"><Hamburger open={menuOpen} setOpen={setMenuOpen} /><div className="calculator-title">{calculatorTitle}</div></div>
            <div className="calculator-body">
                <BoardFootCalculatorPad />
            </div>
        </div>
    );
}
