import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import LumberPage from "./pages/LumberPage";
import TilePage from "./pages/TilePage";
import ToolsPage from "./pages/ToolsPage";
import PrivacyPage from "./privacy/PrivacyPage";
import TermsOfUsePage from "./termsofuse/TermsOfUsePage";
import HandToolsPage from "./tools/HandToolsPage";
import PowerToolsPage from "./tools/PowerToolsPage";

export default function createAppRouter() {
    const router = createBrowserRouter(
        [
            {
                path: "/", element: <App />, errorElement: <ErrorPage />, children:
                    [
                        { path: "", element: <HomePage /> },
                        { path: "home", element: <HomePage /> },
                        { path: "lumber", element: <LumberPage /> },
                        { path: "tile", element: <TilePage /> },
                        { path: "tools", element: <ToolsPage /> },
                        { path: "handtools", element: <HandToolsPage /> },
                        { path: "powertools", element: <PowerToolsPage /> },
                        { path: "about", element: <AboutPage /> },
                        { path: "contact", element: <ContactPage /> },
                        { path: "privacy", element: <PrivacyPage /> },
                        { path: "termsofuse", element: <TermsOfUsePage /> },
                    ]
            },
        ]
    );
    return router;
}
