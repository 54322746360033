import ToggleSwitch from "../../components/ToggleSwitch";
import { PrivacySettingSectionProps } from "./PrivacySettingSectionProps";

export default function TargetingCookies(props: PrivacySettingSectionProps) {
    const { checked } = props;
    const { onToggled } = props;
    return (
        <div className="privacy-settings-content">
            <div className="private-settings-tab-content-header">
                <div>
                    <ToggleSwitch label="" checked={checked} onChange={onToggled} name="targeting-cookies"/>
                </div>
                <div>
                    <h2>Targeting Cookies</h2>
                </div>
            </div>
            <p>These cookies may be set through our site by our advertising partners.
                They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
                They do not store directly personal information, but are based on uniquely identifying your browser and internet device.
                If you do not allow these cookies, you will experience less targeted advertising.</p>
        </div>
    );
}
