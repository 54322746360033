import { roundToFixed, toFixedNumber } from "../functions/NumberMethods";

export class NumberFieldValue {
    private _text: string = '';
    private _value: number = 0;
    private _fractionDigits: number | undefined = undefined;

    constructor(text: string, precision?: number | undefined) {
        this._fractionDigits = precision;
        this.text = text;
    }
    public get text() {
        return this._text;
    }
    public set text(text: string) {
        this._text = text;
        this._value = toFixedNumber(text, this._fractionDigits);
    }
    public get value() {
        return this._value;
    }
    public set value(value: number) {
        this._value = roundToFixed(value, this._fractionDigits);
        this._text = value.toFixed(this._fractionDigits);
    }
    public get precision(): number | undefined {
        return this._fractionDigits;
    }
    public set precision(value: number | undefined) {
        this._fractionDigits = value;
    }
    public isNotZero(): boolean {
        return this._value !== 0;
    }
    public clear(): void {
        this._text = '';
        this._value = 0;
    }
}