import React from 'react';
import '../css/app.css';
import './HamburgerMenu.css';

export default function Hamburger(props: any) {
    const { open, setOpen } = props;
    return (
        <>
            <div className="hamburger" onClick={() => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </>
    );
}