import React from 'react';
import '../css/app.css';
import AppVideo from '../AppVideo';
import './AboutPage.css';

export default function AboutPage() {
    return (
        <div className="app-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <div><title>About DIY Workshop</title></div>
                    <p>We created DIY Workshop to pull together the best information we could find from people we thought did a great job of explaining projects in the DIY World.</p>
                    <h3>Bourbon Moth</h3>
                    <p>One of our favorites is Jason Hibbs of <a href="https://www.bourbonmoth.com/" target="_blank" rel="noreferrer">Borbon Moth</a>.</p>
                    <AppVideo url="https://www.youtube.com/watch?v=89WeF52XwcI&t=1s" />
                </div>
            </div>
        </div>
    );
}
