import React from "react";

export default function ToolListImage(props: any) {
    const { imageName, alt } = props;
    var image;
    var element;
    try {
        image = require("../images" + imageName);

        if (image !== null) {
            element = <img className="tool-list-image" src={image} alt={alt} />;
        }
    } catch (error) {
        element = <span>Image with name {imageName} does not exist</span>;
    }
    return (
        <>
            {element}
        </>
    );
}
