import React from 'react';
import { CompanyShortName } from '../AppMetadata';
import '../css/app.css';
import './PrivacyPage.css';

export default function PrivacyDataCollectionSection() {
    return (
        <div>
            <h2>Information Collection and Use by {CompanyShortName()}</h2>
            <p></p>
            <p>Some of the information we collect on this Website is considered private and personal. The Personally Identifiable Information that we collect from you includes, for instance, personal information, and demographic information submitted to {CompanyShortName()}, including without limitation: names, addresses, email addresses, phone numbers, preferred communication methods, user names, and passwords. This Personally Identifiable Information personally identifies you and is used by {CompanyShortName()} to communicate with you, to provide you with the information or other materials you have requested, for relevant marketing purposes and as necessary to conduct our business and as otherwise stated in this Privacy Policy.   These include the following:</p>

            <ul>
                <li>We may send you requested information on our products and services (including those of our affiliates and strategic partners);</li>
                <li>We may communicate with you about information, goods and services that we believe are relevant to your interests, including, upcoming events, webinars, trade shows or conferences, changes to the Website, our goods or services, or other news about products and services (whether offered by {CompanyShortName()}, our affiliates, or our strategic alliance partners) we believe would be of interest to you based on your role;</li>
                <li>If you are currently using {CompanyShortName()} products or services and request support, we may use your Personally Identifiable Information to provide support in connection with your use of the {CompanyShortName()} products and services.</li>
                <li>We may contact you to request feedback about your experience with our products and/or services or learn about your role, demographics, preferences or interests;</li>
                <li>We may use your Personally Identifiable Information for marketing purposes and market research;</li>
                <li>We may use your Personally Identifiable Information to internally administer and improve this Website or to help us improve our services; or</li>
                <li>We may use your Personally Identifiable Information to investigate possible fraud or other violations of our <a href="/termsofuse">Terms of Use</a> or this Privacy Policy and to comply with applicable laws or regulations.</li>
                {CompanyShortName()} also collects Non-Personally Identifiable Information from you, including without limitation: Internet protocol addresses, profile information, aggregate User-data, demographic information, geographical information, browser types, operating system types, Website browsing history, and Website usage statistics. This Non-Personally Identifiable Information is used to manage the Website, track Website usage, and in our marketing efforts. This Non-Personally Identifiable Information may be shared with third-parties to provide more relevant services and third party content to users. IP addresses may also be recorded for security and monitoring purposes.
            </ul>

            <p>Except as otherwise described in this Privacy Policy, or where you are expressly informed otherwise, we do not sell, rent, share, trade, or give away any Personally Identifiable Information to any third parties.</p>
        </div>
    );
}
