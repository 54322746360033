import React from 'react';
import '../css/app.css';
import TileCalculator from '../calculators/TileCalculator';
import TilePeiRatingList from '../tile/TilePeiRatingList';
import TilePeiRatings from '../tile/TilePeiRatings';
import TileTypeList from '../tile/TileTypeList';
import TileTypes from '../tile/TileTypes';
import './TilePage.css';

export default function TilePage() {
    return (
        <div className="app-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <title>Tile</title>
                    <div>
                        <h2>Tile Calculator</h2>
                        <TileCalculator />
                    </div>
                    <div>
                        <h2>Tile Types</h2>
                        <TileTypeList tileTypes={TileTypes.allTypes} caption="Tile Types" />
                    </div>
                    <div>
                        <h2>Best Tile for Floors</h2>
                        <p>How much wear and tear the tile will undergo and your style preferences are both important factors when choosing the best tiles for floors.
                            For example, your living room floor tiles will probably not experience as much traffic as your mudroom.
                            While a durable porcelain tile works well in high-traffic areas, like entryways, the best types of floor tiles for most rooms are those with an added or natural non-slip resistant surface such as cement or travertine tiles.</p>
                        <p>Tip: As a rule of thumb, for floors, choose durable tiles with a hardness rating of Class 3 and up.</p>
                    </div>
                    <div>
                        <h2>Best Tile for Walls</h2>
                        <p>When choosing wall tiles for a DIY wall tile project, choose tiles that look like stone instead or go for the natural look of wood-like tiles.
                            These types of tiles are usually made of porcelain.
                            They are a budget-friendly way to add the rustic look of real hardwoods to a wall.</p>
                    </div>
                    <div>
                        <h2>PEI Ratings</h2>
                        <TilePeiRatingList ratings={TilePeiRatings.allRatings} caption="PEI Tile Ratings" />
                    </div>
                </div>
            </div>
        </div>
    );
}
