import React from 'react';
import '../css/app.css';
import BasicCalculator from '../calculators/BasicCalculator';
import './HomePage.css';
import homelogo from '../images/DIYToolsForHome.png';
import AppImage from '../AppImage';
import GoogleBodyAd from '../ads/GoogleBodyAd';
//import AppImage from '../AppImage';

export default function HomePage() {
    return (
        <div className="app-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <div className="home-header">
                        <img src={homelogo} className="home-logo" alt="logo" />
                        <div>
                            <title>DIY Workshop</title>
                            <div>
                                <p>Welcome to the DIY Workshop. The DIY Workshop is a reference site for information about do-it-yourself home improvement projects.
                                    Information has been selected, organized, and presented using professional and expert knowledge from around the web.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>Let us help you find the best articles and videos that can guide you through aspects of your DIY projects that you have never done.
                            We'll provide curated links to vidoes produced by people we think do an outstanding job of showing you how to approach your projects.</p>
                        {/*    <p>DIY Workshop includes reveiws of some of the most important tools you can have in your own workshop.</p>*/}
                    </div>
                    <div>
                        <GoogleBodyAd />
                    </div>
                    <div className="home-bottom-contents">
                        <div className="home-calculator">
                            <BasicCalculator />
                            {/*    <BoardFootCalculator />*/}
                        </div>
                        <div>
                            {<AppImage className="home-power-tools-image" imageName="/DewaltPowerToolsHome.jpg" alt="Dewalt Power Tools" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
