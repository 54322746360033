import '../css/app.css';
import ToolCategory from './ToolCategory';
import './Tools.css';

export default function ToolCategoryList(props: any) {
    const { toolCategories, caption } = props;
    const createToolCategoryList = () => {
        const toolCategoryRows: JSX.Element[] = [];

        toolCategories.forEach((toolCategory: ToolCategory) => {
            toolCategoryRows.push(
                <tr><td>{toolCategory.name}</td><td>{toolCategory.description}</td></tr>
            )
        });
        return toolCategoryRows;
    }
    return (
        <div>
            <table className="tool-table">
                <caption>{caption}</caption>
                <thead>
                    <tr>
                        <th className="tool-category-th">Tool</th><th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {createToolCategoryList()}
                </tbody>
            </table>
        </div>
    );
}