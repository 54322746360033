import GetCookie, { Cookies, DeleteCookie, SetCookie } from "../../functions/Cookies";

export interface ITileCalculatorCookie {
    areaWidth: string,
    areaLength: string,
    tileWidth: string,
    tileLength: string,
    groutJointSizeName: string,
    tilesRequired: string,
    area: string,
    tilesPerBox: string,
    totalBoxes: string,
    costPerTile: string,
    totalCost: string,
}

export class TileCalculatorCookie implements ITileCalculatorCookie {
    areaWidth: string = '';
    areaLength: string = '';
    tileWidth: string = '';
    tileLength: string = '';
    groutJointSizeName: string = '';
    tilesRequired: string = '';
    area: string = '';
    tilesPerBox: string = '';
    totalBoxes: string = '';
    costPerTile: string = '';
    totalCost: string = '';
}
export function GetTileCalculatorCookie(): TileCalculatorCookie {
    var tileCalculatorCookieJson: string | null = GetCookie(Cookies.Names.TileCalculatorCookieName);
    var tileCalculatorCookie: TileCalculatorCookie;

    if (tileCalculatorCookieJson !== null) {
        try {
            tileCalculatorCookie = JSON.parse(tileCalculatorCookieJson);
        }
        catch {
            tileCalculatorCookie = new TileCalculatorCookie();
        }
    } else {
        tileCalculatorCookie = new TileCalculatorCookie();
    }

    return tileCalculatorCookie;
}
export function SetTileCalculatorCookie(tileCalculatorCookie: TileCalculatorCookie | null): void {
    var deleteCookie: boolean = true;
    if (tileCalculatorCookie !== null) {
        if (tileCalculatorCookie.areaWidth || tileCalculatorCookie.areaLength || tileCalculatorCookie.tileWidth || tileCalculatorCookie.tileLength) {
            var tileCalculatorCookieJson = JSON.stringify(tileCalculatorCookie);
            SetCookie(Cookies.Names.TileCalculatorCookieName, tileCalculatorCookieJson);
            deleteCookie = false;
        }
    }
    if (deleteCookie) {
        DeleteCookie(Cookies.Names.TileCalculatorCookieName);
    }
    return;
}
export function DeletePrivacyConsentCookie(): void {
    DeleteCookie(Cookies.Names.TileCalculatorCookieName);
    return;
}
