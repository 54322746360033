import { CalculatorOperator } from "./CalculatorOperator";

export default class CalculatorStackEntry {
    constructor(text: string, operator: CalculatorOperator | undefined) {
        this.text = text;
        this.operator = operator;
    }
    public text: string = "";
    public operator: CalculatorOperator | undefined;
    public append(text: string)
    {
        this.text = this.text + text;
    }
    public isOperator(): boolean {
        return this.operator !== undefined;
    }
    public isOperand(): boolean {
        return this.operator === undefined;
    }
    public toNumber(): number {
        return Number(this.text);
    }
}