import React from 'react';
import { Link } from 'react-router-dom';
import '../css/app.css';
import '../pages/ToolsPage.css';
import { ToolCategoryCode } from './ToolCategoryCode';
import ToolList from './ToolList';
import { ToolPowerCode } from './ToolPowerCode';
import Tools from './Tools';


export default function PowerToolsPage() {
    return (
        <div className="app-page">
            <title>Power Tools</title>
            <Link to="/tools" className="App-body-link" >
                Back to Tools
            </Link>
            <h2>Drills</h2>
            <ToolList tools={Tools.getTools([ToolPowerCode.Battery], [ToolCategoryCode.Drills])} caption="Drills" />
        </div>
    );
}
