import { CalculatorOperator } from "./CalculatorOperator";
import CalculatorStackEntry from "./CalculatorStackEntry";

export default class CalculatorStack {
    public stack: CalculatorStackEntry[] = [];
    clear() {
        this.stack.length = 0;
    }
    pushEntry(entry: CalculatorStackEntry) {
        this.stack.push(entry);
    }
    pushOperator(operator: CalculatorOperator) {
        this.stack.push(new CalculatorStackEntry(operator.toString(), operator));
    }
    pushOperand(text: string) {
        this.stack.push(new CalculatorStackEntry(text, undefined));
    }
    pop(): CalculatorStackEntry | undefined {
        if (this.stack.length > 0) {
            return this.stack.pop();
        }
        return undefined;
    }
    peek(): CalculatorStackEntry | undefined {
        if (this.stack.length > 0) {
            return this.stack[this.stack.length-1];
        }
        return undefined;
    }
    isOperatorOnTop(): boolean {
        var stackEntry: CalculatorStackEntry | undefined = this.peek();

        if (stackEntry !== undefined) {
            if (stackEntry.isOperator()) {
                return true;
            }
        }

        return false;
    }
    isOperandOnTop(): boolean {
        var stackEntry: CalculatorStackEntry | undefined = this.peek();

        if (stackEntry !== undefined) {
            if (stackEntry.isOperand()) {
                return true;
            }
        }

        return false;
    }
    appendToTop(value: string) {
        var stackEntry: CalculatorStackEntry | undefined = this.peek();

        if (stackEntry !== undefined) {
            stackEntry.append(value);
        }
    }
    toExpression(): string {
        if (this.stack.length > 0) {
            return this.stack.map((stackEntry) => stackEntry.text).join('');
        }
        return "";
    }
//    pushNumber(value: number) : void {
//        if (this.isOperandOnTop()) {
//            this.appendToTop(value.toString());
//        } else {
//            this.pushOperand(value.toString());
//        }
//        setExpression(stack.toExpression());
//    }
}