import '../css/app.css';
import TileType from './TileType';
import './Tiles.css';

export default function TileTypeList(props: any) {
    const { tileTypes, caption } = props;
    const createTileTypeList = () => {
        const tileTypeRows: JSX.Element[] = [];

        tileTypes.forEach((tileType: TileType) => {
            tileTypeRows.push(
                <tr><td>{tileType.name}</td><td>{tileType.description}</td></tr>
            )
        });
        return tileTypeRows;
    }
    return (
        <div>
            <table className="tool-table">
                <caption>{caption}</caption>
                <thead>
                    <tr>
                        <th>Tool</th><th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {createTileTypeList()}
                </tbody>
            </table>
        </div>
    );
}