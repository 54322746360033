import React, { useRef, useState } from 'react';
import './css/app.css';
import { isMobileScreen } from './AppMetadata';
import { AppNavBarLogo } from './AppImages';
import { Link } from 'react-router-dom';
import AppMenu from './AppMenu';

export default function AppNavBar() {
    const hamburgerButton = useRef<HTMLInputElement>(null);
    const [menuDisplaySetting, setMenuDisplaySetting] = useState(isMobileScreen() ? "none" : "flex");

    const handleMenuItemNavigating = (e: any) => {
        if (hamburgerButton.current !== null) {
            if (hamburgerButton.current.checked) {
                hamburgerButton.current.checked = false;

                setMenuDisplaySetting("none");
            }
        }
        e.preventDefault();
    };

    React.useEffect(() => {
        window.addEventListener('menuItem:navigating', handleMenuItemNavigating);
        // cleanup this component
        return () => {
            window.removeEventListener('menuItem:navigating', handleMenuItemNavigating);
        };
    }, []);
    React.useEffect(() => {
        function handleWindowResize() {
            setMenuDisplaySetting(isMobileScreen() ? "none" : "flex");
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    //let mobileMenu: boolean = (hamburgerButton.current !== null && hamburgerButton.current.checked) ? true : false;
    const toggleMenu = (e: any) => {
        if (hamburgerButton.current !== null) {
            setMenuDisplaySetting((hamburgerButton.current.checked) ? "flex" : "none");
        }
    }
    const onCloseButton = (e: any) => {
        if (hamburgerButton.current !== null) {
            if (hamburgerButton.current.checked) {
                hamburgerButton.current.checked = false;

                setMenuDisplaySetting("none");
            }
        }
        e.preventDefault();
    }
    return (
        <div className="app-navbar">
            <input ref={hamburgerButton} className="hamburger-button" type="checkbox" id="hamburger-button" onChange={toggleMenu} />
            <label className="hamburger-label" htmlFor="hamburger-button"><span className="hamburger-icon"></span></label>
            <div className="app-navbar-logo"><Link to="/"><img src={AppNavBarLogo()} alt="logo" /></Link></div>
            <AppMenu displaySetting={menuDisplaySetting} onCloseButton={onCloseButton} />
        </div >
    );
}
