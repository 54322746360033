import ToolCategory from "./ToolCategory";
import { ToolCategoryCode } from "./ToolCategoryCode";

export default class ToolClassifications {
    public static allCategories: ToolCategory[] = [
        new ToolCategory("Chisels", ToolCategoryCode.Chisels, "tbd"),
        new ToolCategory("Drills", ToolCategoryCode.ScrewDrivers, "A hand tool, power tool, or machine with a rotating cutting tip or reciprocating hammer or chisel, used for making holes."),
        new ToolCategory("Files", ToolCategoryCode.Files, "tbd"),
        new ToolCategory("Hammers", ToolCategoryCode.ScrewDrivers, "A tool with a heavy metal head mounted at right angles at the end of a handle, used for jobs such as breaking things and driving in nails."),
        new ToolCategory("Pliers", ToolCategoryCode.Pliers, "tbd"),
        new ToolCategory("Punches", ToolCategoryCode.Punches, "tbd"),
        new ToolCategory("Screw Drivers", ToolCategoryCode.ScrewDrivers, "A screwdriver is a tool, manual or powered, used for turning screws"),
    ]
}