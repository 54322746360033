import React from "react";
import ReactPlayer from "react-player";

export default function AppVideo(props: any) {
    const { url } = props;
    return (
        <>
            <ReactPlayer url={url} />
        </>
    );
}
