export function CompanyLegalName() {
    return "OnPoint Technologies, LLC";
}
export function CompanyFormationLocationAndType() {
    return "Colorado Limited Liability Company";
}
export function CompanyName() {
    return (<>OnPoint Technologies</>);
}
export function CompanyShortName() {
    return (<>OnPoint</>);
}
export function AppName() {
    return (<>DIY Workshop</>);
}
export function AppWebsite() {
    return (<>www.diywork.shop</>);
}
export function MailToPrivacyLink() {
    return (<a href="mailto:privacy@onpoint.com">privacy@onpoint.com</a>);
}
export function MailToInfoLink() {
    return (<a href="mailto:privacy@onpoint.com">info@onpoint.com</a>);
}
export const mobileScreenWidthBreakpoint: number = 768;
export const isMobileScreen = () => {
    const { innerWidth } = window;
    const { outerWidth } = window;
    return (innerWidth <= mobileScreenWidthBreakpoint) || (outerWidth <= mobileScreenWidthBreakpoint);
}
export const windowWidth = () => {
    const { innerWidth } = window;
    return innerWidth;
}
export const reCaptchaSiteKey: string = "6Lcdc4cjAAAAAK1XpRj46SR-KN64CgxrtCt9YqwW"
export const reCaptchaSecretKey: string = "6Lcdc4cjAAAAAD3Oncm_cOy9j2MFD61Y0kzLSdXf"
