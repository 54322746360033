export default class SelectedTab {
    private _selectedTabId: string;
    private _onTabSelected: Function | null;

    constructor(selectedTabId: string, onTabSelected: Function | null) {
        this._selectedTabId = selectedTabId;
        this._onTabSelected = onTabSelected;
    }
    public get selectedTabId() {
        return this._selectedTabId;
    }
    public set selectedTabId(tabId: string) {
        this._selectedTabId = tabId;
    }
    public get onTabSelected(): Function | null {
        return this._onTabSelected;
    }
    public set onTabSelected(onTabSelected: Function | null) {
        this._onTabSelected = onTabSelected;
    }
}