export default class LumberDimension {
    constructor(nominalDepth: number, nominalWidth: number, actuallDepth: number, actuallWidth: number) {
        this.nominalDepth = nominalDepth;
        this.nominalWidth = nominalWidth;
        this.actuallDepth = actuallDepth;
        this.actuallWidth = actuallWidth;
    }
    public nominalDepth: number = 0;
    public nominalWidth: number = 0;
    public actuallDepth: number = 0;
    public actuallWidth: number = 0;
    public getNominalStringInInches(): string {
        return this.nominalDepth + "x" + this.nominalWidth;
    }
    public getActualStringInInches(): string {
        return this.actuallDepth + "x" + this.actuallWidth;
    }
}