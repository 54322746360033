import TilePeiRating from "./TilePeiRating";
import { TilePeiRatingCode } from "./TilePeiRatingCode";

// https://www.tilewarehouse.co.nz/commercial/blog-news-articles/what-is-pei-rating/
// https://tilesnstone.com/what-is-pei-rating-what-are-the-grades-of-porcelain-tile/
export default class TilePeiRatings {
    public static allRatings: TilePeiRating[] = [
        new TilePeiRating(TilePeiRatingCode.Zero, "Glazed tiles in this class are wall tiles only and unsuitable for use on floors."),
        new TilePeiRating(TilePeiRatingCode.One, "Up to 154 revolutions. For use on very light-wear areas, using soft footwear."),
        new TilePeiRating(TilePeiRatingCode.Two, "300, 450, 600 revolutions Light traffic. Very light-wear, mainly bathrooms and areas using soft-soled or normal footwear. Wall use and residential floor applications."),
        new TilePeiRating(TilePeiRatingCode.Three, "750, 900, 1200, 1500 revolutions Light to moderate traffic. Average domestic homes. Countertops, walls, and floors for normal foot traffic."),
        new TilePeiRating(TilePeiRatingCode.Four, "1500+ revolutions Moderate to heavy traffic. All residential applications as well as medium commercial and light institutional."),
        new TilePeiRating(TilePeiRatingCode.Five, "12000 revolutions Heavy to extra heavy traffic. All residential and heavy commercial and institutional foot traffic, stores, entrance halls, shops & hotel floors."),
    ]
}