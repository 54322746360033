import React, { useState } from 'react';
import '../css/app.css';
import { IComboBoxItem } from './IComboBoxItemCollection';

//type TabsControlProps = {
//    list: IComboBoxItemCollection;
//    value: string;
//    onChange?: ChangeEventHandler<HTMLSelectElement> | undefined;
//};
export function ComboBox(props: any) {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const { list } = props;
    const { onChange } = props;

    //if (defaultSelectedTabId) {
    //    selectedTabId = defaultSelectedTabId;
    //}
    //if (activeTabId !== '') {
    //    selectedTabId = activeTabId;
    //}
    //if (vertical) {
    //    tabsClassName = 'tabs-vertical';
    //}
    //const onTabSelected = (tabId: string) => {
    //    setActiveTabId(tabId);
    //    onTabChanged(tabId);
    //}
    const createComboBoxItems = () => {
        const comboBoxItems: JSX.Element[] = [];
        let index: number = 0;

        list.forEach((comboBoxItem: IComboBoxItem) => {
            if (index === selectedIndex) {
                comboBoxItems.push(
                    <option value={comboBoxItem.itemKey} selected>{comboBoxItem.itemText}</option>
                )
            } else {
                comboBoxItems.push(
                    <option value={comboBoxItem.itemKey}>{comboBoxItem.itemText}</option>
                )
           }
            index++;
        });
        return comboBoxItems;
    }
    const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (typeof onChange === 'function') {
            onChange(event);
            setSelectedIndex(event.currentTarget.selectedIndex);
        }
    }
    return (
        <select className="app-combobox" onChange={onChangeHandler}>
            {createComboBoxItems()}
        </select>
    );
}
