import LumberDimension from "./LumberDimension";

export default class LumberDimensionCollection {
    public static lumberDimensions: LumberDimension[] = [new LumberDimension(1, 2, .75, 1.5),
        new LumberDimension(1, 3, .75, 2.5),
        new LumberDimension(1, 4, .75, 3.5),
        new LumberDimension(1, 5, .75, 4.5),
        new LumberDimension(1, 6, .75, 5.5),
        new LumberDimension(1, 7, .75, 6.5),
        new LumberDimension(1, 8, .75, 7.5),
        new LumberDimension(1, 9, .75, 8.5),
        new LumberDimension(1, 10, .75, 9.5),
        new LumberDimension(1, 11, .75, 10.5),
        new LumberDimension(1, 12, .75, 11.5),

        new LumberDimension(2, 2, 1.75, 1.5),
        new LumberDimension(2, 3, 1.75, 2.5),
        new LumberDimension(2, 4, 1.75, 3.5),
        new LumberDimension(2, 5, 1.75, 4.5),
        new LumberDimension(2, 6, 1.75, 5.5),
        new LumberDimension(2, 7, 1.75, 6.5),
        new LumberDimension(2, 8, 1.75, 7.25),
        new LumberDimension(2, 10, 1.75, 9.25),
        new LumberDimension(2, 12, 1.75, 11.25),
        
        new LumberDimension(4, 4, 3.5, 3.5),
        new LumberDimension(4, 6, 3.5, 5.5),
        new LumberDimension(6, 6, 5.5, 5.5),
    ]
}