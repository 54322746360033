import React from "react";

export default function AppImage(props: any) {
    const { className, imageName, alt } = props;
    var image;
    var element;
    try {
        image = require("/images" + imageName);

        if (image !== null) {
            element = <img className={`${className}`} src={image} alt={alt} />;
        }
    } catch (error) {
        element = <span>Image with name {imageName} does not exist</span>;
    }
    return (
        <>
            { element }
        </>
        );
}
