import React, { useState } from 'react';
import '../css/app.css';
import './Calculator.css';
import './BoardFootCalculator.css';
import { convertToNumber } from '../functions/NumberMethods';
import { NumberFieldValue } from '../components/NumberFieldValue';

export default function BoardFootCalculatorPad(props: any) {
    //    const { pushOperator, pushNumber, pushDecimal, calculate, clearEntry, clearAll } = props;
    const [boardThickness, setBoardThickness] = useState<string>('');
    const [boardWidth, setBoardWidth] = useState<string>('');
    const [boardLength, setBoardLength] = useState<string>('');
    const [numberOfBoards, setNumberOfBoards] = useState<string>('1');
    const [boardFoot, setBoardFoot] = useState<string>('');
    const [totalBoardFoot, setTotalBoardFoot] = useState<string>('');
    const [totalSquareFeet, setTotalSquareFeet] = useState<string>('');
    const [totalLinearFeet, setTotalLinearFeet] = useState<string>('');

    let thicknessField: NumberFieldValue = new NumberFieldValue(boardThickness);
    let widthField: NumberFieldValue = new NumberFieldValue(boardWidth);
    let lengthField: NumberFieldValue = new NumberFieldValue(boardLength);
    let numberOfBoardsField: NumberFieldValue = new NumberFieldValue(numberOfBoards);
    let boardFootField: NumberFieldValue = new NumberFieldValue(boardFoot);

    let totalBoardFeetNumber: number = convertToNumber(totalBoardFoot);
    let totalSquareFeetNumber: number = convertToNumber(totalSquareFeet);
    let totalLinearFeetNumber: number = convertToNumber(totalLinearFeet);

    const onBoardThicknessChanged = (event: React.FormEvent<HTMLInputElement>) => {
        thicknessField.text = event.currentTarget.value;
        calculateBoardFoot();
        setBoardThickness(thicknessField.text);
    };
    const onBoardWidthChanged = (event: React.FormEvent<HTMLInputElement>) => {
        widthField.text = event.currentTarget.value;
        calculateBoardFoot();
        setBoardWidth(widthField.text);
    };
    const onBoardLengthChanged = (event: React.FormEvent<HTMLInputElement>) => {
        lengthField.text = event.currentTarget.value;
        calculateBoardFoot();
        setBoardLength(lengthField.text);
    };
    const onNumberOfBoardsChanged = (event: React.FormEvent<HTMLInputElement>) => {
        numberOfBoardsField.text = event.currentTarget.value;
        calculateBoardFoot();
        setNumberOfBoards(numberOfBoardsField.text);
    };
    const calculateBoardFoot = () => {
        let thicknessInInches = 0;
        let widthInInches = 0;
        let lengthInInches = 0;

        thicknessInInches = thicknessField.value;
        widthInInches = widthField.value;
        lengthInInches = lengthField.value * 12;

        boardFootField.value = ((thicknessInInches * widthInInches * lengthInInches) / 144);
        setTotalBoardFoot((boardFootField.value * numberOfBoardsField.value).toString());
        setTotalSquareFeet((((lengthInInches / 12) * (widthInInches / 12)) * numberOfBoardsField.value).toString());
        setTotalLinearFeet((lengthField.value * numberOfBoardsField.value).toString());

        setBoardFoot(boardFootField.text);
    }
    //const convertToInches = (value: string) => {
    //    let numericValue = 0;

    //    numericValue = parseFloat(value);

    //    if (isNaN(numericValue)) {
    //        numericValue = 0;
    //    }

    //    return numericValue;
    //}
    return (
        <div className="board-feet-pad">
            <div>Thickness:</div><div><input tabIndex={1} autoFocus value={boardThickness} onChange={onBoardThicknessChanged} /> (inches)</div>
            <div>Width:</div><div><input tabIndex={2} value={boardWidth} onChange={onBoardWidthChanged} /> (inches)</div>
            <div>Length:</div><div><input tabIndex={3} value={boardLength} onChange={onBoardLengthChanged} /> (feet)</div>
            <div>Number of Boards:</div><div><input tabIndex={4} value={numberOfBoards} onChange={onNumberOfBoardsChanged} /> (count)</div>
            {/*    <div className="board-feet-expression">*/}
            {/*        <div className="board-feet-expression-left-side">*/}
            {/*            <div className="board-feet-expression-numerator">Thickness X Width X Length</div>*/}
            {/*            <div className="board-feet-expression-denominator">144</div>*/}
            {/*        </div>*/}
            {/*        <div className="board-feet-expression-right-side">*/}
            {/*            = Board Feet*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="board-feet-results-row">*/}
            {/*        <div className="board-feet-results-label-column">*/}
            {/*            <div className="board-feet-label">Board Foot per Piece:</div>*/}
            {/*            <div className="board-feet-label">Total Board Feet:</div>*/}
            {/*            <div className="board-feet-label">Total Linear Feet:</div>*/}
            {/*       </div>*/}
            {/*        <div className="board-feet-results-value-column">*/}
            {/*            <div className="board-feet-highlight">{boardFootNumber}</div>*/}
            {/*            <div className="board-feet-highlight">{totalBoardFeetNumber}</div>*/}
            {/*            <div className="board-feet-highlight">{totalLinearFeetNumber}</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            <div className="calculator-results-field">
                <div className="board-feet-label">Board Foot per Piece:</div><div><input value={boardFootField.value} readOnly /></div>
                <div className="board-feet-label">Total Board Feet:</div><div><input value={totalBoardFeetNumber} readOnly /></div>
                <div className="board-feet-label">Total Square Feet:</div><div><input value={totalSquareFeetNumber} readOnly /></div>
                <div className="board-feet-label">Total Linear Feet:</div><div><input value={totalLinearFeetNumber} readOnly /></div>
            </div>
        </div>
    );
}