import '../css/app.css';
import TilePeiRating from './TilePeiRating';
import './Tiles.css';

export default function TilePeiRatingList(props: any) {
    const { ratings, caption } = props;
    const createTilePeiRatingList = () => {
        const tilePeiRatingRows: JSX.Element[] = [];

        ratings.forEach((tilePeiRating: TilePeiRating) => {
            tilePeiRatingRows.push(
                <tr><td className="tile-rating-column">{tilePeiRating.rating}</td><td>{tilePeiRating.description}</td></tr>
            )
        });
        return tilePeiRatingRows;
    }
    return (
        <div>
            <table className="tool-table">
                <caption>{caption}</caption>
                <thead>
                    <tr>
                        <th>Rating</th><th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {createTilePeiRatingList()}
                </tbody>
            </table>
        </div>
    );
}