import React from 'react';
import '../css/app.css';
import ToolListImage from './ToolListImage';
import './Tools.css';
import ToolSpecification from './ToolSpecification';

export default function ToolList(props: any) {
    const { tools, caption } = props;
    const createToolList = () => {
        const toolRows: JSX.Element[] = [];

        tools.forEach((tool: ToolSpecification) => {
            if (tool.imageName.length > 0) {
                toolRows.push(
                    <tr><td>{tool.name}</td><td><ToolListImage imageName={`${tool.imageName}`} alt={`${tool.name}`} /></td><td>{tool.description}</td></tr>
                )
            } else {
                toolRows.push(
                    <tr><td>{tool.name}</td><td></td><td>{tool.description}</td></tr>
                )
            }
        });
        return toolRows;
    }
    return (
        <div>
            <table className="tool-table">
                <caption>{caption}</caption>
                <thead>
                    <tr>
                        <th className="tool-list-name-th">Tool</th><th className="tool-list-image-th">Image</th><th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {createToolList()}
                </tbody>
            </table>
        </div>
    );
}