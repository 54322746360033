import React from 'react';
import '../../css/app.css';

export default function YourPrivacy() {
    return (
        <div className="privacy-settings-content">
            <div className="private-settings-tab-content-header">
                <div>
                    <h2>YourPrivacy</h2>
                </div>
            </div>
            <p>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies.
                This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to.
                The information does not usually directly identify you, but it can give you a more personalized web experience.
                Because we respect your right to privacy, you can choose not to allow some types of cookies.
                Click on the different category headings to find out more and change our default settings.
                However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>
        </div>
    );
}
