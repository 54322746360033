import React from 'react';
import { Link, useLocation, useRouteError } from 'react-router-dom';
import { AppName, AppWebsite } from '../AppMetadata';
import '../css/app.css';
import './ErrorPage.css';

export default function ErrorPage() {
    const error = useRouteError() as any;
    const location = useLocation();
    const errorText = error.statusText + " (" + error.status + ")";
    const errorDetails = () => {
        if (error.status === 404) {
            return "The page " + location.pathname + " you requested was not found. Please check the URL and try again.";
        }
        return "The page " + location.pathname + " you requested returned an unexpected error. Please check the URL and try again.";
    }
    return (
        <div className="error-page">
            <title>{AppName()} Website</title>
            <h2>{errorText}</h2>
            <p>
                {errorDetails()}
            </p>
            <p>
                <Link to="/">
                    Click on {AppWebsite()} to return to the home page.
                </Link>
            </p>
        </div>
    );
}
