import '../../css/app.css';
import CookieConsent, { HasCookieConsent } from './CookieConsent';

export default function ShowCookieConsent() {
    if (!HasCookieConsent()) {
        return (
            <div className="app-section">
                <div className="app-section-inner" style={{ alignItems: "center" }}>
                    <CookieConsent />
                </div>
            </div>
        );
    }
    return <></>;
}
