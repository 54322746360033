import React from 'react';
import ToggleSwitch from '../../components/ToggleSwitch';
import '../../css/app.css';
import { PrivacySettingSectionProps } from './PrivacySettingSectionProps';

export default function PerformanceCookies(props: PrivacySettingSectionProps) {
    const { checked } = props;
    const { onToggled } = props;
    return (
        <div className="privacy-settings-content">
            <div className="private-settings-tab-content-header">
                <div>
                    <ToggleSwitch label="" checked={checked} onChange={onToggled} />
                </div>
                <div>
                    <h2>PerformanceCookies</h2>
                </div>
            </div>
            <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.
                They help us to know which pages are the most and least popular and see how visitors move around the site.
                If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>
            <p><b>All information these cookies collect is aggregated and anonymous.</b></p>
        </div>
    );
}
