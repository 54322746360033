import React from 'react';
import { Link } from "react-router-dom";
import './css/app.css';
import { AppStyles } from './AppStyles';
import AppCopyright from './AppCopyright';

export default function AppFooter() {
    return (
        <div className="app-footer">
            <div>
                <Link to="/" style={AppStyles.Links.FooterLinkStyle}>
                    Home
                </Link>
            </div>
                <div>
                    <Link to="/about" style={AppStyles.Links.FooterLinkStyle}>
                        About
                    </Link>
                </div>
                <div>
                    <Link to="/contact" style={AppStyles.Links.FooterLinkStyle}>
                        Contact
                    </Link>
                </div>
            <div>
                <Link to="/privacy" style={AppStyles.Links.FooterLinkStyle}>
                    Privacy
                </Link>
            </div>
            <div>
                <Link to="/termsofuse" style={AppStyles.Links.FooterLinkStyle}>
                    Terms of Use
                </Link>
            </div>
            <div>
                <div><AppCopyright /></div>
            </div>
        </div >
    );
}
