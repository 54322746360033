import React from 'react';
import '../css/app.css';
import './PrivacyPage.css';
import PrivacySettings from './cookies/PrivacySettings';
import PrivacyPolicySection from './PrivacyPolicySection';
import PrivacyDataCollectionSection from './PrivacyDataCollectionSection';
import PrivacyCookiesSection from './PrivacyCookiesSection';
import PrivacyChangesSection from './PrivacyChangesSection';
import PrivacyContactSection from './PrivacyContactSection';
import PrivacySecuritySection from './PrivacySecuritySection';
import PrivacyChildrenSection from './PrivacyChildrenSection';
import PrivacyLinksSection from './PrivacyLinksSection';

export default function PrivacyPage() {
    return (
        <div className="privacy-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <div className="privacy-header">
                        <div className="privacy-header-title">
                            <div>
                                <h1 className="privacy-h1">Privacy and Cookies</h1>
                            </div>
                        </div>
                    </div>
                    <PrivacySettings />
                    <div className="privacy-body">
                        <div>
                            Last updated: November 3, 2022.<br />
                            <i>Please note that this privacy policy will be updated on a regular basis to reflect any changes in the way we handle personal data or any changes in applicable laws.</i>
                        </div>
                        <PrivacyPolicySection />
                        <PrivacyDataCollectionSection />
                        <PrivacyCookiesSection />
                        <PrivacyLinksSection />
                        <PrivacyChildrenSection />
                        <PrivacySecuritySection />
                        <PrivacyChangesSection />
                        <PrivacyContactSection />
                    </div>
                </div>
            </div>
        </div>
    );
}
