import { ToolCategoryCode } from "./ToolCategoryCode";

export default class ToolSpecification {
    constructor(name: string, classification: ToolCategoryCode, description: string) {
        this.name = name;
        this.classification = classification;
        this.description = description;
    }
    public name: string = "";
    public classification: ToolCategoryCode = ToolCategoryCode.None;
    public description: string = "";
}