import React from 'react';
import '../css/app.css';
import BoardFootCalculator from '../calculators/BoardFootCalculator';
import LumberDimension from '../lumber/LumberDimension';
import LumberDimensionCollection from '../lumber/LumberDimensionCollection';
import './LumberPage.css';

export default function LumberPage() {
    const createLumberDimensionRows = () => {
        const lumberDimensionRows: JSX.Element[] = [];

        LumberDimensionCollection.lumberDimensions.forEach((lumberDimension: LumberDimension) => {
            lumberDimensionRows.push(
                <tr><td>{lumberDimension.getNominalStringInInches()}</td><td>{lumberDimension.getActualStringInInches()}</td></tr>
            )
        });
        return lumberDimensionRows;
    }
    return (
        <div className="app-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <div><title>Lumber</title></div>
                    <div className="lumber-calculator">
                        <div>
                            <BoardFootCalculator />
                        </div>
                    </div>
                    <div>
                        <h2>Nominal and Actual Lumber Sizes</h2>
                        <p>The numbers given for the size of a piece of lumber are nominal lumber sizes or measurements.
                            This number indicates the size the wood was originally cut to.
                            Before it&#39;s ready to be sold, dimensional lumber is dried and then planed to make it smooth.
                            Wood shrinks as it dries and the planing or surfacing process removes some of the original material.
                            This means that the wood&#39;s actual measurements are now different from the nominal measurements.</p>
                        <p>If your project requires very specific measurements, it&#39;s important to check what the actual measurements of your lumber are before you purchase it.</p>
                        <table>
                            <caption>Dimensional Lumber Sizes</caption>
                            <thead>
                                <tr>
                                    <th className="lumber-dimension-nominal-th">Nominal Size</th><th className="lumber-dimension-actual-th">Actual Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createLumberDimensionRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
