import { ComboBoxItem } from "../components/IComboBoxItemCollection";
import GroutJointSize from "./GroutJointSize";

export default class GroutJointSizes {
    public static allSizes: GroutJointSize[] = [
        new GroutJointSize("1/16", 0.0625, "1/16\""),
        new GroutJointSize("1/8", 0.125, "1/8\""),
        new GroutJointSize("3/16", 0.1875, "3/16\""),
        new GroutJointSize("1/4", 0.25, "1/4\""),
        new GroutJointSize("3/8", 0.375, "3/8\""),
    ];
    public static allSizesItems: ComboBoxItem[] = [
        new ComboBoxItem(this.allSizes[0].name, this.allSizes[0].description, this.allSizes[0]),
        new ComboBoxItem(this.allSizes[1].name, this.allSizes[1].description, this.allSizes[1]),
        new ComboBoxItem(this.allSizes[2].name, this.allSizes[2].description, this.allSizes[2]),
        new ComboBoxItem(this.allSizes[3].name, this.allSizes[3].description, this.allSizes[3]),
        new ComboBoxItem(this.allSizes[4].name, this.allSizes[4].description, this.allSizes[4]),
    ];
}