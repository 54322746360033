import React from 'react';
import '../css/app.css';
import Hamburger from '../components/Hamburger';
import '../components/HamburgerMenu.css';
import HamburgerMenuItem from '../components/HamburgerMenuItem';

export default function CalculatorMenu(props: any) {
    const { open, setOpen } = props;
    let menuClassName: string = 'hamburger-menu-closed';
    if (open) {
        menuClassName = "hamburger-menu-open"
    }
    const setBasicCalculator = () => {
        setOpen(false);
    }
    const setBoardFootCalculator = () => {
        setOpen(false);
    }
    return (
        <>
            <div className={menuClassName}>
                <Hamburger open={open} setOpen={setOpen} />
                <div className="hamburger-menu-items">
                    <HamburgerMenuItem caption="Basic Calculator" clicked={setBasicCalculator} />
                    <HamburgerMenuItem caption="Board Foot Calculator" clicked={setBoardFootCalculator} />
                </div>
            </div>
        </>
    );
}