import React from 'react';
import '../css/app.css';
import './PrivacyPage.css';

export default function PrivacyChangesSection() {
    return (
        <div>
            <h2>Changes to the Privacy Policy</h2>
            <p>We may change this Privacy Policy due to changes in the law or our business practices. We encourage you to periodically review this privacy statement to stay informed about how we are helping to protect the personal information we collect.</p>
        </div>
    );
}
