import { ToolCategoryCode } from "./ToolCategoryCode";
import { ToolPowerCode } from "./ToolPowerCode";

export default class ToolSpecification {
    constructor(name: string, classification: ToolCategoryCode, power: ToolPowerCode, imageName: string, description: string) {
        this.name = name;
        this.classification = classification;
        this.power = power;
        this.imageName = imageName;
        this.description = description;
    }
    public name: string = "";
    public classification: ToolCategoryCode = ToolCategoryCode.None;
    public power: ToolPowerCode = ToolPowerCode.Hand;
    public description: string = "";
    public imageName: string = "";
}