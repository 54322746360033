import TileType from "./TileType";
import { TileTypeCode } from "./TileTypeCode";

export default class TileTypes {
    public static allTypes: TileType[] = [
        new TileType("Cement", TileTypeCode.Cement, "Cement"),
        new TileType("Ceramic", TileTypeCode.Ceramic, "Ceramic"),
        new TileType("Marble", TileTypeCode.Marble, "Marble"),
        new TileType("Metal", TileTypeCode.Metal, "Metal"),
        new TileType("Mosaic", TileTypeCode.Mosaic, "Mosaic"),
        new TileType("Natural Stone", TileTypeCode.NaturalStone, "Natural Stone"),
        new TileType("Porcelain", TileTypeCode.Porcelain, "Porcelain"),
    ]
}