import React from 'react';
import '../css/app.css';
import './Calculator.css';
import { CalculatorOperator } from './CalculatorOperator';

export default function CalculatorNumberPad(props: any) {
    const { pushOperator, pushNumber, pushDecimal, calculate, clearEntry, clearAll } = props;
    return (
        <div className='calculator-buttons'>
            <div className='calculator-number-pad'>
                <div className='calculator-number-pad-row'>
                    <button className="calculator-hidden-button"></button>
                    <button className="calculator-operator-button" onClick={() => { clearEntry() }}>CE</button>
                    <button className="calculator-operator-button" onClick={() => { clearAll() }}>C</button>
                    <button className="calculator-operator-button" onClick={() => { pushOperator(CalculatorOperator.Divide) }}>/</button>
                </div>
                <div className='calculator-number-pad-row'>
                    <button onClick={() => pushNumber(7)} key="7">7</button>
                    <button onClick={() => pushNumber(8)} key="8">8</button>
                    <button onClick={() => pushNumber(9)} key="9">9</button>
                    <button className="calculator-operator-button" onClick={() => { pushOperator(CalculatorOperator.Multiply) }}>x</button>
                </div>
                <div className='calculator-number-pad-row'>
                    <button onClick={() => pushNumber(4)} key="4">4</button>
                    <button onClick={() => pushNumber(5)} key="5">5</button>
                    <button onClick={() => pushNumber(6)} key="6">6</button>
                    <button className="calculator-operator-button" onClick={() => { pushOperator(CalculatorOperator.Subtract) }}>-</button>
                </div>
                <div className='calculator-number-pad-row'>
                    <button onClick={() => pushNumber(1)} key="1">1</button>
                    <button onClick={() => pushNumber(2)} key="2">2</button>
                    <button onClick={() => pushNumber(3)} key="3">3</button>
                    <button className="calculator-operator-button" onClick={() => { pushOperator(CalculatorOperator.Add) }}>+</button>
                </div>
                <div className='calculator-number-pad-row'>
                    <button className="calculator-double-button"onClick={() => { pushNumber(0) }}>0</button>
                    <button onClick={() => { pushDecimal('.') }}>.</button>
                    <button className="calculator-operator-button" onClick={() => { calculate() }}>=</button>
                </div>
            </div>
        </div>
    );
}
