import React from 'react';
import '../css/app.css';

export default function GoogleBodyAd() {
    const styles = {
        display: 'block'
    }
    return (
        <>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5177578346835240" crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={styles}
                data-ad-client="ca-pub-5177578346835240"
                data-ad-slot="2105934993"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({ });
            </script>
        </>
    );
}
