import React, { useState } from 'react';
import '../css/app.css';
import SelectedTab from './SelectedTab';
import './TabControls.css';

const TabsControlContext = React.createContext<SelectedTab>(new SelectedTab('', null));

//type TabsControlProps = {
//    defaultSelectedTabId: string;
//    vertical: string;
//    onTabChanged: (activeTabId: string) => void;
//};
export function TabsControl(props: any) {
    const [activeTabId, setActiveTabId] = useState<string>('');
    const { defaultSelectedTabId } = props;
    const { vertical } = props;
    const { onTabChanged } = props;
    let tabsClassName: string = 'tabs-horizontal';
    let selectedTabId: string = '';

    if (defaultSelectedTabId) {
        selectedTabId = defaultSelectedTabId;
    }
    if (activeTabId !== '') {
        selectedTabId = activeTabId;
    }
    if (vertical) {
        tabsClassName = 'tabs-vertical';
    }
    const onTabSelected = (tabId: string) => {
        setActiveTabId(tabId);
        onTabChanged(tabId);
    }
    return (
        <TabsControlContext.Provider value={new SelectedTab(selectedTabId, onTabSelected)}>
            <div className={tabsClassName}>
                {props.children}
            </div>
        </TabsControlContext.Provider>
    );
}

type TabControlProps = {
    tabId: string;
    label: string;
};
export function TabControl(props: TabControlProps) {
    const { tabId } = props;
    const { label } = props;

    //const isActive = (selectedTabId: string): boolean => {
    //    return (tabId === selectedTabId);
    //}
    const getTabContainerClassName = (selectedTabId: string): string => {
        if (tabId === selectedTabId) {
            return 'tabs-active-tab-container';
        }
        return 'tabs-inactive-tab-container';
    }
    const getTabClassName = (selectedTabId: string): string => {
        if (tabId === selectedTabId) {
            return 'tabs-active-tab';
        }
        return 'tabs-inactive-tab';
    }
    return (
        <TabsControlContext.Consumer>
            {({ selectedTabId, onTabSelected }) => (
                <>
                    <div className={getTabContainerClassName(selectedTabId)} >
                        <button id={tabId} className={getTabClassName(selectedTabId)} onClick={(e) => { onTabSelected?.(tabId); e.stopPropagation(); }}>{label}</button>
                    </div>
                </>
            )}
        </TabsControlContext.Consumer>
    );
}
