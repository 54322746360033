import React from 'react';
import '../css/app.css';
import './ContactPage.css';
import moreInformationImage from '../images/MoreInformation.png';
import siliconmountain from '../images/silicon-mountain.png';

export default function ContactPage() {
    return (
        <div className="app-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <div className="contact-details">
                        <div className="contact-center">
                            <div>
                                <img src={moreInformationImage} className="more-information-image" alt="more information" />
                            </div>
                            <div>
                                <h1>Contact Us</h1>
                                <p>Thank you for your interest in DIY Workshop a <span className="llc">OnPoint Technologies</span> App. To contact us, please send us your inquiry to</p><p><a href="mailto:info@onpoint.com">info@onpoint.com</a></p>
                            </div>
                        </div>
                        <div className="contact-center">
                            <div>
                                <span className="llc">OnPoint Technologies, LLC</span>
                                <br />
                                <span>Castle Rock, Colorado</span>
                            </div>
                            <img src={siliconmountain} className="silicon-mountain-image" alt="silicon mountain" />
                            <p>Proud to be part of the Silicon Mountain in the Colorado Front Range.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
