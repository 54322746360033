import React, { useState } from 'react';
import '../css/app.css';
import Hamburger from '../components/Hamburger';
import './Calculator.css';
import CalculatorMenu from './CalculatorMenu';
import CalculatorNumberPad from './CalculatorNumberPad';
import { CalculatorOperator } from './CalculatorOperator';
import CalculatorStack from './CalculatorStack';

export default function BasicCalculator() {
    const [menuOpen, setMenuOpen] = useState(false)
    const [calculatorTitle/*, setCalculatorTitle*/] = useState('DIY Calculator')
    const [expression, setExpression] = useState('')
    const [entry, setEntry] = useState('')
    const [stack] = useState(new CalculatorStack())
    /* eslint-disable */
    var evaluate = eval;
    /* eslint-enable */
    const pushNumber = (value: number) => {
        setEntry(entry + value.toString());
    }
    const pushDecimal = (value: string) => {
        setEntry(entry + value.toString());
    }
    const pushOperator = (operator: CalculatorOperator) => {
        if (entry !== '') {
            stack.pushOperand(entry);
            clearEntry();
        }
        stack.pushOperator(operator);
        setExpression(stack.toExpression());
    }
    const calculate = () => {
        if (entry !== '') {
            stack.pushOperand(entry);
            clearEntry();
        }
        var results = evaluate(stack.toExpression()).toString();
        setExpression(results);
        setEntry(results);

        stack.clear();
    }
    const clearEntry = () => {
        setEntry('');
    }
    const clearAll = () => {
        stack.clear();
        setEntry('');
        setExpression('');
    }
    const onKeyPress = (event: React.KeyboardEvent): void => {
        switch (event.key) {
            case "Enter":
                calculate();
                event.stopPropagation();
                break;
            case "=":
                calculate();
                event.stopPropagation();
                break;
            case "+":
                pushOperator(CalculatorOperator.Add);
                event.stopPropagation();
                break;
            case "-":
                pushOperator(CalculatorOperator.Subtract);
                event.stopPropagation();
                break;
            case "*":
                pushOperator(CalculatorOperator.Multiply);
                event.stopPropagation();
                break;
            case "/":
                pushOperator(CalculatorOperator.Divide);
                break;
            case ".":
                pushDecimal(event.key);
                event.stopPropagation();
                break;
            default:
                if (event.key >= "0" && event.key <= "9") {
                    pushNumber(parseInt(event.key));
                    event.stopPropagation();
                }
                break;
        }
    }
    return (
        <div className="calculator" tabIndex={0} onKeyPress={onKeyPress}>
            <CalculatorMenu open={menuOpen} setOpen={setMenuOpen} />
            <div className="calculator-titlebar"><Hamburger open={menuOpen} setOpen={setMenuOpen} /><div className="calculator-title">{calculatorTitle}</div></div>
            <div className="calculator-body">
                <div className='calculator-results'>
                    {entry || '0'}
                </div>
                <div className='calculator-expression'>
                    {expression || '0'}
                </div>
                <CalculatorNumberPad pushOperator={pushOperator} pushNumber={pushNumber} pushDecimal={pushDecimal} calculate={calculate} clearEntry={clearEntry} clearAll={clearAll} />
            </div>
        </div>
    );
}
