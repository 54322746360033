import React from 'react';
import { CompanyShortName } from '../AppMetadata';
import '../css/app.css';
import './PrivacyPage.css';

export default function PrivacyCookiesSection() {
    return (
        <div className="privacy-cookie-notice">
            <h2>Cookie Notice and Use of Cookies</h2>
            <p>{CompanyShortName()} may use cookies to store User's preferences and to record session information. A cookie is a piece of data stored on the User's computer tied to information about the User. At our discretion, we may use both "session ID cookies" and "persistent cookies." More specifically, we may use session ID cookies to confirm that Users are logged in thereby making it easier for users to navigate our Services or Website. These cookies terminate once the User closes the browser. A persistent cookie, on the other hand, remains on the hard drive for an extended period of time. Note also that on some Internet browsers, Users may optionally use a cookie to remember their e-mail address in order to automatically log in to the Website. Each browser is different, but a User can remove persistent cookies by following directions provided in the Internet browsers "help" file. Please note that, if a User rejects cookies or disable cookies in his or her web browser, some features of the Website may not work.</p>
            <p>Please note that {CompanyShortName()} may allow third parties that are presenting Third Party Content on some of our pages to set and access their cookies on a User's computer. Third Party Content provider's use of cookies is subject to their own privacy policies, and not this Privacy Policy.</p>

            <h2>Types Of Cookies Used</h2>
            <p>We use different types of cookies to run the Website. Some or all of the cookies identified below may be stored in your browser. You can view and manage cookies in your browser (though browsers for mobile devices may not offer this visibility).</p>
            <table className="privacy-cookie-table">
                <thead className="privacy-cookie-thead">
                    <tr>
                        <th className="privacy-cookie-type-th">Type of Cookie</th>
                        <th className="privacy-cookie-example-th">Example</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="privacy-cookie-type-td">Processes &amp;<br />Security</td>
                        <td>
                            <p>Process cookies help make the website work and deliver services that the website visitor expects, like navigating around web pages or accessing secure areas of the website. Without these cookies, the website cannot function properly.</p>

                            <p>We use security cookies to authenticate users, prevent fraudulent use of login credentials, and protect user data from unauthorized parties.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="privacy-cookie-type-td">Advertising</td>
                        <td>
                            <p>We use cookies to make advertising more engaging to users.</p>

                            <p>As you browse the Website, advertising cookies will be placed on your computer so that we can understand what you are interested in. Our display advertising partner enables us to present you with retargeting advertising on other sites based on your previous interaction with the Website. The techniques our partners employ do not collect personal information such as your name, email address, postal address, or telephone number.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="privacy-cookie-type-td">Session State</td>
                        <td>
                            <p>Websites often collect information about how users interact with the Website. This may include the pages users visit most often and whether users get error messages from certain pages. We use these so-called "session state cookies" to help us improve our services, in order to improve our users' browsing experience. Blocking or deleting these cookies will not render the website unusable.</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="privacy-cookie-type-td">Analytics</td>
                        <td>
                            <p>We use analytics tools that help us understand how the Website visitors engage with the site. The tools may use a set of cookies to collect information and report website usage statistics without personally identifying individual visitors.</p>

                            <p>In addition to reporting website usage statistics, the Analytics can also be used, together with some of the advertising cookies described above, to help show more relevant ads and to measure interactions with the ads we show.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
