import React from 'react';
import '../css/app.css';
import { CompanyShortName, MailToPrivacyLink } from '../AppMetadata';
import './PrivacyPage.css';

export default function PrivacyChildrenSection() {
    return (
        <div>
            <h2>Children (Minors under 13)</h2>
            <p>The Website is not intended for use by children under the age of 13.
                {CompanyShortName()} does not knowingly collect or solicit personal information from anyone under the age of thirteen (13).
                In the event that we learn that we have collected Personally Identifiable Information from a minor under age 13, we reserve the right to deactivate any access to the Website or content; however, we will retain that person's Personally Identifiable Information in accordance with state and federal laws pertaining to retention and protection of such information.
                If you believe that we might have any information from or about a minor under thirteen (13), please contact us at <MailToPrivacyLink/>.</p>
        </div>
    );
}
