import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './css/app.css';
import AppFooter from './AppFooter';
import AppNavBar from './AppNavBar';
import ShowCookieConsent from './privacy/cookies/ShowCookieConsent';

export default function App() {
    return (
        <div className="app">
            <header className="app-header">
                <AppNavBar />
            </header>
            <div className="app-body">
                <Outlet />
                <ShowCookieConsent />
            </div>
            <AppFooter />
        </div>
    );
}
