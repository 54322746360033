import * as React from 'react';
import './ToggleSwitch.css';
import { ToggleSwitchProps } from './ToggleSwitch.types';

export default function ToggleSwitch(props: ToggleSwitchProps | any) {
    const [checked, setChecked] = React.useState(props.checked);
    const { onChange } = props;

    const onCheckChanged = (e: any) => {
        onChange(e.target, e.target.checked);
        setChecked(e.target.checked);
    }
    const onToggleSwitchClicked = (e: any) => {
        onChange(e.target, !checked);
        setChecked(!checked);
    }
    return (
        <div className="toggle-switch small-switch" onClick={onToggleSwitchClicked}>
            <input type="checkbox" className="toggle-switch-checkbox" name={props.Name} id={props.Name} onChange={onCheckChanged} checked={checked} />
            <label className="toggle-switch-label" htmlFor={props.Name}>
                <span className="toggle-switch-inner" />
                <span className="toggle-switch-switch" />
            </label>
        </div>
    );
}
