import React from 'react';
import '../css/app.css';
import { AppWebsite, CompanyFormationLocationAndType, CompanyLegalName, CompanyShortName, MailToPrivacyLink } from '../AppMetadata';
import './PrivacyPage.css';

export default function PrivacyPolicySection() {
    return (
        <div>
            <h2>Privacy Policy Related to this Website</h2>
            <p>This Website (<AppWebsite />) is operated by {CompanyLegalName()}, a {CompanyFormationLocationAndType()}, and its corporate affiliates and subsidiaries (collectively, "{CompanyShortName()}", "we", "us", or "our").</p>

            <p>When you use and interact with our Website, communicate with or otherwise contact us or visit our offices or attend our events, we may collect, use, share and process information relating to you.
                This Privacy Policy describes our Personally Identifiable Information processing practices and your related rights.
                In which case, {CompanyShortName()} is the controller of your Personally Identifiable Information and is responsible for its processing.For the avoidance of doubt,
                this Privacy Policy does not apply to the extent we process Personally Identifiable Information the role of a processor on behalf of our customers.</p >

            <p>By providing your Personally Identifiable Information via this Website, you consent to the use of that information in accordance with this Privacy Policy.
                Please read this Privacy Policy carefully, so that you are fully informed about our privacy policies.
                If you have any questions concerning this Privacy Policy, please e-mail us at <MailToPrivacyLink />.</p >
        </div>
    );
}
