import * as React from 'react';

export default function CloseButton(props: any) {
    const { onClick } = props;

    return (
        <>
            <div className="close-button" onClick={onClick} id={props.Name}></div>
        </>
    );
}
