
export namespace Cookies {
    export class Names {
        public static readonly PrivacyConsentCookieName = "PrivacyConsent";
        public static readonly LumberCalculatorCookieName = "LumberCalculator";
        public static readonly TileCalculatorCookieName = "TileCalculator";
    };
} export default function GetCookie(name: string): string | null {
    const nameLenPlus = (name.length + 1);
    var cookieValue;
    cookieValue = document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;

    return cookieValue;
}
export function SetCookie(name: string, value: string | null): void {
    var expires = "";
    var days = 180;
    var cookie;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    cookie = name + "=" + (value || "") + expires + "; path=/";
    document.cookie = cookie;
    return;
}
export function DeleteCookie(name: string): void {
    var expires = "";
    var date = new Date();
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + expires + "; path=/";
    return;
}
