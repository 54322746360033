import React, { useState } from 'react';
import '../css/app.css';
import { ComboBox } from '../components/ComboBox';
import Hamburger from '../components/Hamburger';
import { NumberFieldValue } from '../components/NumberFieldValue';
import { GetTileCalculatorCookie, SetTileCalculatorCookie, TileCalculatorCookie } from '../tile/cookies/TileCalculatorCookie';
import GroutJointSize from '../tile/GroutJointSize';
import GroutJointSizes from '../tile/GroutJointSizes';
import './Calculator.css';
import CalculatorMenu from './CalculatorMenu';

export default function TileCalculator() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [areaLength, setAreaLength] = useState<string>('');
    const [areaWidth, setAreaWidth] = useState<string>('');
    const [tileLength, setTileLength] = useState<string>('');
    const [tileWidth, setTileWidth] = useState<string>('');
    const [groutJointSizeIndex, setGroutJointSizeIndex] = useState<number>(-1);
    const [tilesRequired, setTilesRequired] = useState<string>('');
    const [area, setArea] = useState<string>('');
    const [tilesPerBox, setTilesPerBox] = useState<string>('');
    const [totalBoxes, setTotalBoxes] = useState<string>('');
    const [costPerTile, setCostPerTile] = useState<string>('');
    const [totalCost, setTotalCost] = useState<string>('');

    const calculatorTitle: string = 'Tile Calculator';
    let tileCalculatorCookie: TileCalculatorCookie = GetTileCalculatorCookie();
    
    if (tileCalculatorCookie === null) {
        tileCalculatorCookie = new TileCalculatorCookie();
    }
    
    /* eslint-disable */
    var evaluate = eval;
    /* eslint-enable */

    const getFieldValue = (value1: string, value2: string): string => {
        if (value1 !== '') {
            return value1;
        }
        if (value2 !== '') {
            return value2;
        }
        return '';
    }

    let areaLengthField: NumberFieldValue = new NumberFieldValue(getFieldValue(areaLength, tileCalculatorCookie.areaLength));
    let areaWidthField: NumberFieldValue = new NumberFieldValue(getFieldValue(areaWidth, tileCalculatorCookie.areaWidth));
    let tileLengthField: NumberFieldValue = new NumberFieldValue(getFieldValue(tileLength, tileCalculatorCookie.tileLength));
    let tileWidthField: NumberFieldValue = new NumberFieldValue(getFieldValue(tileWidth, tileCalculatorCookie.tileWidth));
    let groutJointSizeIndexValue: number = groutJointSizeIndex;
    let tilesRequiredField: NumberFieldValue = new NumberFieldValue(getFieldValue(tilesRequired, tileCalculatorCookie.tilesRequired));
    let areaField: NumberFieldValue = new NumberFieldValue(getFieldValue(area, tileCalculatorCookie.area));
    let tilesPerBoxField: NumberFieldValue = new NumberFieldValue(getFieldValue(tilesPerBox, tileCalculatorCookie.tilesPerBox));
    let totalBoxesField: NumberFieldValue = new NumberFieldValue(getFieldValue(totalBoxes, tileCalculatorCookie.totalBoxes),1);
    let costPerTileField: NumberFieldValue = new NumberFieldValue(getFieldValue(costPerTile, tileCalculatorCookie.costPerTile));
    let totalCostField: NumberFieldValue = new NumberFieldValue(getFieldValue(totalCost, tileCalculatorCookie.totalCost), 2);
   
    const onAreaLengthChanged = (event: React.FormEvent<HTMLInputElement>) => {
        areaLengthField.text = event.currentTarget.value;
        calculateArea();
        setAreaLength(areaLengthField.text);
    };
    const onAreaWidthChanged = (event: React.FormEvent<HTMLInputElement>) => {
        areaWidthField.text = event.currentTarget.value;
        calculateArea();
        setAreaWidth(areaWidthField.text);
    };
    const calculateArea = () => {
        areaField.value = areaLengthField.value * areaWidthField.value;
        setArea(areaField.text);
        calculateTilesRequired();
    }
    const onTileLengthChanged = (event: React.FormEvent<HTMLInputElement>) => {
        tileLengthField.text = event.currentTarget.value;
        calculateTilesRequired();
        setTileLength(tileLengthField.text);
    };
    const onTileWidthChanged = (event: React.FormEvent<HTMLInputElement>) => {
        tileWidthField.text = event.currentTarget.value;
        calculateTilesRequired();
        setTileWidth(tileWidthField.text);
    };
    const onGroupSizeSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value) {
            groutJointSizeIndexValue = event.currentTarget.selectedIndex;
            setGroutJointSizeIndex(groutJointSizeIndexValue);
            calculateTilesRequired();
        }
    }
    const onTilesPerBoxChanged = (event: React.FormEvent<HTMLInputElement>) => {
        tilesPerBoxField.text = event.currentTarget.value;
        calculateTilesRequired();
        setTilesPerBox(tilesPerBoxField.text);
    };
    const onCostPerTileChanged = (event: React.FormEvent<HTMLInputElement>) => {
        costPerTileField.text = event.currentTarget.value;
        calculateTilesRequired();
        setCostPerTile(costPerTileField.text);
    };
    const calculateTilesRequired = () => {
        let tileSqFeet = (tileLengthField.value / 12) * (tileWidthField.value / 12);
        let groutJointSize: GroutJointSize = GroutJointSizes.allSizes[groutJointSizeIndexValue];
        let groutJointSizeName: string = '';

        if (!isNaN(tileSqFeet) && tileSqFeet !== 0) {
            if (groutJointSize != null) {
                tileSqFeet = ((tileLengthField.value + groutJointSize.width) / 12) * ((tileWidthField.value + groutJointSize.width) / 12);
                groutJointSizeName = groutJointSize.name;
            }
            
            tilesRequiredField.value = areaField.value / tileSqFeet;
            tilesRequiredField.value = Math.ceil(tilesRequiredField.value);
            setTilesRequired(tilesRequiredField.text);
        } else {
            setTilesRequired('');
        }

        if (tilesPerBoxField.isNotZero()) {
            totalBoxesField.value = tilesRequiredField.value / tilesPerBoxField.value;
        } else {
            totalBoxesField.clear();
        }
        setTotalBoxes(totalBoxesField.text);

        if (costPerTileField.isNotZero()) {
            totalCostField.value = tilesRequiredField.value * costPerTileField.value;
        } else {
            totalCostField.clear();
        }
        setTotalCost(totalCostField.text);
        
        tileCalculatorCookie.areaLength = areaLengthField.text;
        tileCalculatorCookie.areaWidth = areaWidthField.text;
        tileCalculatorCookie.tileLength = tileLengthField.text;;
        tileCalculatorCookie.tileWidth = tileWidthField.text;
        tileCalculatorCookie.area = areaField.text;
        tileCalculatorCookie.groutJointSizeName = groutJointSizeName;
        tileCalculatorCookie.tilesRequired = tilesRequiredField.text;
        tileCalculatorCookie.tilesPerBox = tilesPerBoxField.text;
        tileCalculatorCookie.totalBoxes = totalBoxesField.text;
        tileCalculatorCookie.costPerTile = costPerTileField.text;
        tileCalculatorCookie.totalCost = totalCostField.text;
        
        SetTileCalculatorCookie(tileCalculatorCookie);
    }
    return (
        <div className="calculator" tabIndex={0}>
            <CalculatorMenu open={menuOpen} setOpen={setMenuOpen} />
            <div className="calculator-titlebar"><Hamburger open={menuOpen} setOpen={setMenuOpen} /><div className="calculator-title">{calculatorTitle}</div></div>
            <div className="calculator-body">
                <div className="calculator-body-left">
                    <div>Area Length:</div><div className="calculator-input-field"><input tabIndex={1} autoFocus value={areaLengthField.text} onChange={onAreaLengthChanged} /></div>
                    <div>Area Width:</div><div className="calculator-input-field"><input tabIndex={2} value={areaWidthField.text} onChange={onAreaWidthChanged} /></div>
                    <div>Tile Length:</div><div className="calculator-input-field"><input tabIndex={3} value={tileLengthField.text} onChange={onTileLengthChanged} /></div>
                    <div>Tile Width:</div><div className="calculator-input-field"><input tabIndex={4} value={tileWidthField.text} onChange={onTileWidthChanged} /></div>
                    <div>Grout Join:</div><div className="calculator-input-field"><ComboBox tabIndex={5} value={groutJointSizeIndexValue} list={GroutJointSizes.allSizesItems} onChange={onGroupSizeSelected} /></div>
                </div>
                <div className="calculator-body-right">
                    <div>Tiles required:</div><div className="calculator-results-field"><input value={tilesRequiredField.text} readOnly /></div>
                    <div>Area in Square Feet:</div><div className="calculator-results-field"><input value={areaField.text} readOnly /></div>
                    <div>Tiles per Boxes:</div><div className="calculator-input-field"><input tabIndex={6} value={tilesPerBoxField.text} onChange={onTilesPerBoxChanged} /></div>
                    <div>Total Boxes:</div><div className="calculator-results-field"><input value={totalBoxesField.text} readOnly /></div>
                    <div>Cost per Tile:</div><div className="calculator-input-field"><input tabIndex={7} value={costPerTileField.text} onChange={onCostPerTileChanged} /></div>
                    <div>Total Cost:</div><div className="calculator-results-field"><input value={totalCostField.text} readOnly /></div>
              </div>
            </div>
        </div>
    );
}
