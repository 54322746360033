import React, { useEffect, useRef, useState } from 'react';
import { isMobileScreen } from '../AppMetadata';
import '../css/app.css';

export default function CascadingMenu(props: any) {
    const { caption } = props;
    //const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [/*isMobile*/, setIsMobile] = useState<boolean>(false);
    const cascadingMenu = useRef<HTMLDivElement>(null);

    /* Hook up the outside click listener */
    //const menuRef = useRef(null);
    //const [listening, setListening] = useState<boolean>(false);
    //const [isOpen, setIsOpen] = useState(false);
    //const toggle = () => setIsOpen(!isOpen);

    //const handleMenuItemNavigating = (e: any) => {
    //    if (cascadingMenu.current !== null) {
    //        cascadingMenu.current.style.display = "none";
    //        setMenuOpen(false);
    //    }
    ////    e.preventDefault();
    //};

    //React.useEffect(() => {
    //    window.addEventListener('menuItem:navigating', handleMenuItemNavigating);
    //    // cleanup this component
    //    return () => {
    //        window.removeEventListener('menuItem:navigating', handleMenuItemNavigating);
    //    };
    //}, []);

    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(isMobileScreen());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    //const toggleMenu = (e: any) => {
    //    if (cascadingMenu.current !== null) {
    //        cascadingMenu.current.style.display = (!menuOpen) ? "flex" : "none";
    //        setMenuOpen(!menuOpen);
    //    }
    //}
    //const getOpenStateSymbol = (): string => {
    //    if (isMobile) {
    //        return (!menuOpen) ? "\u{203A}" : "\u{2039}";
    //    }
    //    return (!menuOpen) ? "\u{203A}" : "\u{2039}";
    //}
    let openStateSymbol = "\u{203A}";
    return (
        <div className="cascading-menu">
            <div className="cascading-menu-label">
                <div className="cascading-menu-caption">{caption}&nbsp;</div>
                <div className="cascading-menu-symbol">{openStateSymbol}</div>
            </div>
            <div ref={cascadingMenu} className="cascading-sub-menu">
                {props.children}
            </div>
        </div>
    );
}
