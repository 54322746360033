import { ToolCategoryCode } from "./ToolCategoryCode";
import { ToolPowerCode } from "./ToolPowerCode";
import ToolSpecification from "./ToolSpecification";

export default class Tools {
    public static tools: ToolSpecification[] = [
        new ToolSpecification("Flat Head", ToolCategoryCode.ScrewDrivers, ToolPowerCode.Hand, "/handtools/SlotHeadScrewdriver.jpg", "Flat or slotted tip"),
        new ToolSpecification("Philips Head", ToolCategoryCode.ScrewDrivers, ToolPowerCode.Hand, "/handtools/PhillipsHeadScrewdriver.jpg", "A screwdriver with a tip that is designed to fit into the two intersecting slots of a Phillips screw. Also known as Cross tip."),
        new ToolSpecification("Torx", ToolCategoryCode.ScrewDrivers, ToolPowerCode.Hand, "", "Also known as Star tip."),
        new ToolSpecification("Robertson", ToolCategoryCode.ScrewDrivers, ToolPowerCode.Hand, "", "Also known as Square tip."),

        new ToolSpecification("Claw", ToolCategoryCode.Hammers, ToolPowerCode.Hand, "/handtools/ClawHammer.jpg", "A hammer with one side of the head split and curved, used for extracting nails."),
        new ToolSpecification("Framing", ToolCategoryCode.Hammers, ToolPowerCode.Hand, "/handtools/FramingHammer.jpg", "A framing hammer is a modified type of claw hammer. The claw is straight instead of curved with a longer handle, heavier, and a rough or waffled face to keeps the head from slipping when driving nails."),
        new ToolSpecification("Ball Peen", ToolCategoryCode.Hammers, ToolPowerCode.Hand, "/handtools/BallPeenHammer.jpg", "A hammer with a rounded end opposite the face."),
        new ToolSpecification("Engineering", ToolCategoryCode.Hammers, ToolPowerCode.Hand, "/handtools/EngineeringHammer.jpg", "An engineer's hammer, also known as drilling hammer or a baby sledge hammer, is a tool used for heavy hammering or demolition work."),

        new ToolSpecification("Drill/Driver", ToolCategoryCode.Drills, ToolPowerCode.Battery, "/handtools/DrillCordless.jpg", "A drill is a tool used for making round holes or driving fasteners. It is fitted with a bit, either a drill or driverchuck."),
        new ToolSpecification("Hammer Drill", ToolCategoryCode.Drills, ToolPowerCode.Battery, "/handtools/DrillCordless.jpg", "A hammer drill, also known as a percussion drill or impact drill, is a drill used for drilling in hard materials. It is a type of rotary drill with an impact mechanism that generates a hammering motion"),
    ]
    public static getTools(power: ToolPowerCode[], category: ToolCategoryCode[]): ToolSpecification[] {
        var tools: ToolSpecification[];

        tools = this.tools.filter(t => power.includes(t.power) && category.includes(t.classification));

        return tools;
    }
}