export interface IComboBoxItem {
    get itemKey(): string;
    get itemText(): string;
}
export interface IComboBoxItemCollection {
    [index: number]: IComboBoxItem;
}
export class ComboBoxItem implements IComboBoxItem {
    private _itemKey: string;
    private _itemText: string;
    private _itemObject: object | null;
    
    constructor(itemKey: string, itemText: string, itemObject: object) {
        this._itemKey = itemKey;
        this._itemText = itemText;
        this._itemObject = itemObject;
    }
    get itemKey(): string {
        return this._itemKey;
    }
    get itemText(): string {
        return this._itemText;
    }
    get itemObject(): object | null {
        return this._itemObject;
    }
}
//export class ComboBoxItemCollection implements IComboBoxItemCollection {
    
//}