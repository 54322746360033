export function convertToNumber(value: string, precision?: number | undefined) {
    let numericValue = 0;

    numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
        numericValue = 0;
    }

    return numericValue;
}
export function roundToFixed(value: number, fractionDigits?: number | undefined) {
    let numericValue = value;
    let factor = 1;

    if (isNaN(numericValue)) {
        numericValue = 0;
    } else {
        if (fractionDigits !== undefined) {
            if (fractionDigits !== 0) {
                factor = Math.pow(10, fractionDigits);
                numericValue = Math.round(numericValue * factor) / factor;
            }
        }
    }

    return numericValue;
}
export function toFixedNumber(value: string, fractionDigits?: number | undefined) {
    let numericValue = 0;

    numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
        numericValue = 0;
    } else {
        if (fractionDigits !== undefined) {
            numericValue = Math.round(numericValue);
            numericValue = parseFloat(numericValue.toFixed(fractionDigits));
        }
    }

    return numericValue;
}
