import React from 'react';
import { Link } from 'react-router-dom';
import '../css/app.css';

type MenuItemProps = {
    caption: string;
    to: string;
    onSelected?: (e: React.SyntheticEvent<HTMLElement>) => void;
};

export default function CascadingMenuItem(props: MenuItemProps) {
    const { caption } = props;
    const { to } = props;
    const { onSelected } = props;
    const menuItemNavigatingEvent = new Event('menuItem:navigating');
    
    const onClick = (e: React.SyntheticEvent<HTMLElement>) => {
        if (onSelected !== undefined) {
            onSelected(e);
        }
        dispatchEvent(menuItemNavigatingEvent);
    }
    return (
        <div className="app-menu-item cascading-menu-item">
            <Link to={to} onClick={onClick}>
                {caption}
            </Link>
        </div>
    );
}
