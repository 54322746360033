import React from 'react';
import { Link } from 'react-router-dom';
import '../css/app.css';
import '../pages/ToolsPage.css';
import { ToolCategoryCode } from './ToolCategoryCode';
import ToolList from './ToolList';
import { ToolPowerCode } from './ToolPowerCode';
import Tools from './Tools';


export default function HandToolsPage() {
    return (
        <div className="app-page">
            <title>Hand Tools</title>
            <Link to="/tools" className="App-body-link" >
                Back to Tools
            </Link>
            <h2>Screw Drivers</h2>
            <ToolList tools= {Tools.getTools([ToolPowerCode.Hand], [ToolCategoryCode.ScrewDrivers])} caption="Screw Drivers" />
            <h2>Hammers</h2>
            <ToolList tools={Tools.getTools([ToolPowerCode.Hand], [ToolCategoryCode.Hammers])} caption="Hammers" />
       </div>
    );
}
