import React, { useRef } from 'react';
import './css/app.css';
import { CompanyName } from './AppMetadata';
import MenuItem from './components/MenuItem';
import CloseButton from './components/CloseButton';
import CascadingMenu from './components/CascadingMenu';
import CascadingMenuItem from './components/CascadingMenuItem';

export default function AppMenu(props: any) {
    const { displaySetting } = props;
    const { onCloseButton } = props;
    const menuRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={menuRef} className="app-menu" style={{ display: displaySetting }}>
            <div className="app-menu-header">
                <div className="app-menu-header-label">{CompanyName()}</div>
                <div className="app-menu-header-close"><CloseButton onClick={onCloseButton} /></div>
            </div>
            <MenuItem caption="Home" to="/" />
            <MenuItem caption="Lumber" to="/lumber" />
            <MenuItem caption="Tile" to="/tile" />
            <MenuItem caption="Tools" to="/tools" />
            <CascadingMenu caption="About">
                <CascadingMenuItem caption="Contact" to="/contact" />
            </CascadingMenu>
        </div>
    );
}
