import React from 'react';
import { Link } from 'react-router-dom';
import '../css/app.css';
import ToolCategoryList from '../tools/ToolCategoryList';
import ToolCategories from '../tools/ToolCategories';
import './ToolsPage.css';

export default function ToolsPage() {
    return (
        <div className="app-page">
            <div className="app-section">
                <div className="app-section-inner">
                    <title>Tools</title>
                    <h2>Guide to Tools</h2>
                    <Link to="/handtools" className="App-body-link" >
                        Hand Tools
                    </Link>
                    <br />
                    <Link to="/powertools" className="App-body-link" >
                        Power Tools
                    </Link>
                </div>
            </div>
            <div className="app-section">
                <div className="app-section-inner">
                    <h2>Tool Categories</h2>
                    <ToolCategoryList toolCategories={ToolCategories.allCategories} caption="Tool Categories" />
                </div>
            </div>
        </div>
    );
}
